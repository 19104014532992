import React, { useEffect, useState } from "react";
import { Link } from "gatsby"

import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import { getBlogToken } from "../utils/customApiCall"

import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Fab } from "@mui/material"

import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from "@mui/icons-material/Publish"
import VisibilityIcon from "@mui/icons-material/Visibility"
import TextField from '@mui/material/TextField'

import { BlobServiceClient, AnonymousCredential } from '@azure/storage-blob';
import { TableServiceClient, AzureSASCredential } from '@azure/data-tables';

import { useQuill } from 'react-quilljs'

import 'quill/dist/quill.snow.css';

const storageAccountName = process.env.staticStorageAccount;
const anonymousCredential = new AnonymousCredential();

let blobServiceClient; 

const BlogContent = ({ blogState }) => {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],
    
        [{ list: 'ordered'}, { list: 'bullet' }],
        [{ indent: '-1'}, { indent: '+1' }],

        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image', 'video'],
        [{ color: [] }, { background: [] }],
    
        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
    }
  });
  const [ html, setHtml ] = useState("");
  const [ previewMode, setPreviewMode ] = useState(false);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const authorizedRequest = {
    ...apiRequest,
    account: account
  };

  instance.acquireTokenSilent(authorizedRequest).catch((e) => {
    if (e instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(authorizedRequest);
    }
  });

  async function addFileToAzure (fileName, content) {  
    instance.acquireTokenSilent(authorizedRequest).then(async (response) => {
      let sasToken = await getBlogToken(response.accessToken);

      blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net${sasToken}`, anonymousCredential);
      
      const containerClient = blobServiceClient.getContainerClient("blogs");
    
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    
      const uploadBlobResponse = await blockBlobClient.upload(content, content.length);
      console.log(`Upload block blob ${fileName} successfully`, uploadBlobResponse);
    }).catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(authorizedRequest);
        }
    });
  }

  async function publishBlog() {
    let fileName = window.document.getElementById("blogName").value.replace(" ", "_")

    await addFileToAzure(`${fileName}.blog`, html);
  }

  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  };

  const saveToServer = async (file) => {
    instance.acquireTokenSilent(authorizedRequest).then(async (response) => {
      let sasToken = await getBlogToken(response.accessToken);

      blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net${sasToken}`, anonymousCredential);
      
      const containerClient = blobServiceClient.getContainerClient("images");
    
      let blockBlobClient = containerClient.getBlockBlobClient(file.name);
  
      await blockBlobClient.uploadData(file, {
        rangeSize: 4 * 1024 * 1024, // 4MB range size
        concurrency: 20, // 20 concurrency
        onProgress: ev => console.log(ev)
      });
    
      insertToEditor(`https://${storageAccountName}.blob.core.windows.net/images/${file.name}`);
    }).catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(authorizedRequest);
        }
    });
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };
  
  async function blobToString(blob) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    })
  };

  async function getFileFromAzure (fileName, quill) {  
    let fileContent;
    instance.acquireTokenSilent(authorizedRequest).then(async (response) => {
      let sasToken = await getBlogToken(response.accessToken);

      blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net${sasToken}`, anonymousCredential);
      
      const containerClient = blobServiceClient.getContainerClient("blogs");
    
      const blockBlobClient = containerClient.getBlockBlobClient(`Published/${fileName}.blog`);
    
      const downloadBlockBlobResponse = await blockBlobClient.download();

      fileContent = await blobToString(await downloadBlockBlobResponse.blobBody);

      quill.clipboard.dangerouslyPasteHTML(fileContent);
    }).catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(authorizedRequest);
        }
    });

    return fileContent;
  }

  useEffect(() => {
    async function asyncBlogEffect() {
      if (quill) {
        quill.getModule('toolbar').addHandler('image', selectLocalImage)
  
        let localBlogPageName = "BlogPage"
        let savedContent
        if (blogState) {
          let { blog } = blogState

          if (typeof(blog) !== 'undefined') {
            localBlogPageName = blog.Name
            global.window.document.getElementById("blogName").value = blog.Name.replace(".blog", "")
            global.window.document.getElementById("blogSlug").value = blog.Slug
            global.window.document.getElementById("blogDescription").value = blog.Description
  
            savedContent = window.localStorage.getItem(localBlogPageName)

            if (savedContent) {
              quill.clipboard.dangerouslyPasteHTML(savedContent)
            }
            else {
              savedContent = await getFileFromAzure(localBlogPageName, quill)
            }
          }
          else {
            savedContent = window.localStorage.getItem(localBlogPageName)

            if (savedContent) {
              quill.clipboard.dangerouslyPasteHTML(savedContent)
            }
            else {
              quill.clipboard.dangerouslyPasteHTML('<h1>React Hook for Quill!</h1>');
            }
          }
        }
        else {
          savedContent = window.localStorage.getItem(localBlogPageName)

          if (savedContent) {
            quill.clipboard.dangerouslyPasteHTML(savedContent);
          }
          else {
            quill.clipboard.dangerouslyPasteHTML('<h1>React Hook for Quill!</h1>');
          }
        }
  
        quill.on('text-change', (delta, oldDelta, source) => {
          console.log('Text change!');
          console.log(quill.getText()); // Get text only
          console.log(quill.getContents()); // Get delta contents
          console.log(quill.root.innerHTML); // Get innerHTML using quill
          console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
          //setHtml(quill.root.innerHTML);
          window.localStorage.setItem(localBlogPageName, quill.root.innerHTML);
        });
        
        setHtml(quill.root.innerHTML)
      }
    }
    asyncBlogEffect();
  });

  function switchModes() {
    setPreviewMode(!previewMode)
  }

  return (
    <div>
      <Paper hidden={previewMode ? true : false}>
        <h1>Blog</h1> 
        <TextField
          required
          id="blogName"
          label="Title"
          defaultValue="Blog Title"
          style={{ marginRight: "25px" }}
        />
        <TextField
          required
          id="blogSlug"
          label="Slug"
          defaultValue="blog-slug"
        />
        <br />
        <br />
        <TextField
          required
          multiline
          id="blogDescription"
          label="Description"
          maxRows={4}
          fullWidth
        />
        <br />
        <br />
        <div style={{ minHeight: 300 }}>
          <div ref={quillRef} />
        </div>
      </Paper>
      { previewMode ? <Fab color="secondary" aria-label="edit" id="editButton" className="editPreviewButton" onClick={switchModes}>
        <EditIcon />
      </Fab> : <Fab color="secondary" aria-label="preview" id="previewButton" className="editPreviewButton" onClick={switchModes}>
        <VisibilityIcon />
      </Fab>}
      <Fab color="secondary" aria-label="edit" id="saveButton" className="fabButton" onClick={publishBlog}>
        <PublishIcon />
      </Fab>
      <div id="blogPreview" hidden={previewMode ? false : true}>
        <Container maxWidth="md">
          <Box sx={{ my: 4 }}>
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <Typography variant="h1" component="h1" gutterBottom itemProp="headline">
                  {global?.window?.document?.getElementById("blogName")?.value || "Blog Name"}
                </Typography>
                <p>{new Date().toDateString()}</p>
              </header>
              <section dangerouslySetInnerHTML={{ __html: html }} itemProp="articleBody" />
              <hr />
              <footer>
                <Bio author={{name: "Firstname Lastname.", summary: "This is a placeholder summary for your personal blog bio profile!"}} />
              </footer>
            </article>
          </Box>
        </Container>
      </div>
    </div>
  );
};

const BlogPage = ({ location }) => {
  const { state = {} } = location

  return (
    <Layout>
      <Seo title="Blog" />
      <BlogContent blogState={state} />
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default BlogPage
